// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { JWTTokenConfig } from '../constants/jwt'

const initialUser = () => {
  const item = localStorage.getItem(JWTTokenConfig.storageUserKeyName);
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
}
// const initialUserOrg = () => {
//   const item = localStorage.getItem(JWTTokenConfig.storageUserOrgKeyName);
//   //** Parse stored json or if none return initialValue
//   return item ? JSON.parse(item) : {};
// }
export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    // userOrg: initialUserOrg()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload;
      localStorage.setItem(JWTTokenConfig.storageUserKeyName, JSON.stringify(action.payload));

      // state[JWTTokenConfig.storageTokenKeyName] = action.payload[JWTTokenConfig.storageTokenKeyName];
      // state[JWTTokenConfig.storageRefreshTokenKeyName] = action.payload[JWTTokenConfig.storageRefreshTokenKeyName];
      // localStorage.setItem(JWTTokenConfig.storageTokenKeyName, action.payload.accessToken);
      // localStorage.setItem(JWTTokenConfig.storageRefreshTokenKeyName, action.payload.refreshToken);
    },
    handleLoginToken: (state, action) => {
      state[JWTTokenConfig.storageTokenKeyName] = action.payload[JWTTokenConfig.storageTokenKeyName];
      state[JWTTokenConfig.storageRefreshTokenKeyName] = action.payload[JWTTokenConfig.storageRefreshTokenKeyName];
      localStorage.setItem(JWTTokenConfig.storageTokenKeyName, action.payload.accessToken);
      localStorage.setItem(JWTTokenConfig.storageRefreshTokenKeyName, action.payload.refreshToken);
    },
    // handleUserOrg: (state, action) => {
    //   state.userOrg = action.payload;
    //   localStorage.setItem(JWTTokenConfig.storageUserOrgKeyName, JSON.stringify(action.payload));
    // },
    handleLogout: state => {
      state.userData = {};
      // state.userOrg =[];
      state[JWTTokenConfig.storageTokenKeyName] = null;
      state[JWTTokenConfig.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem(JWTTokenConfig.storageUserKeyName);
      localStorage.removeItem(JWTTokenConfig.storageTokenKeyName);
      localStorage.removeItem(JWTTokenConfig.storageRefreshTokenKeyName);
      // localStorage.removeItem(JWTTokenConfig.storageUserOrgKeyName);

    }
  }
})

export const { handleLogin, handleLogout,handleLoginToken } = authSlice.actions

export default authSlice.reducer
