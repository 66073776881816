import {
    SET_UserOrganizations
  } from "./actionTypes";

  const initialState = {
    userOrganizations: []
  };

  const userOrganization = (state = initialState, action) => {
    switch (action.type) {
      case SET_UserOrganizations:
        state = { ...state, userOrganizations: action.payload };
        break;
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  export default userOrganization;
  